API.FAR = {};

$(function(){
  API.FAR.Resize();
  $(window).resize(API.FAR.Resize);
});

API.FAR.Resize = function(){
  $("[data-force-aspect]").each(function(){
    if($(this).data("force-aspect") !== undefined){
      var ratio = $(this).data("force-aspect").split('/');
      $(this).height( $(this).width() / ratio[0] * ratio[1]);
    }
  });
}
